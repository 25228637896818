var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row bg-white" }, [
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _c(
          "c-card",
          {
            staticClass: "cardClassDetailForm dashborad",
            attrs: { noHeader: true },
          },
          [
            _c("template", { slot: "card-detail" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c("apexchart", {
                    ref: "chart",
                    attrs: {
                      height: "450",
                      type: "bar",
                      width: _vm.chart.chartWidth,
                      options: _vm.chart.chartOptions,
                      series: _vm.chart.series,
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          2
        ),
        _c("c-table", {
          ref: "table",
          staticClass: "q-mt-sm",
          attrs: {
            title: "화학자재(구성성분) 규제법규/허가량",
            tableId: "impr01",
            merge: _vm.grid.merge,
            columns: _vm.grid.columns,
            data: _vm.grid.data,
            gridHeight: "500px",
          },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  col.name === "col1" && props.row.col1
                    ? _vm._l(
                        _vm.chipDatas(props.row["col1"]),
                        function (item, index) {
                          return _c(
                            "q-chip",
                            {
                              key: index,
                              staticStyle: {
                                "margin-bottom": "4px !important",
                              },
                              attrs: {
                                outline: "",
                                square: "",
                                color: "light-green",
                                "text-color": "white",
                              },
                            },
                            [_vm._v(" " + _vm._s(item) + " ")]
                          )
                        }
                      )
                    : _vm._e(),
                  col.name === "col3" && props.row.col3
                    ? _vm._l(
                        _vm.chipDatas(props.row["col3"]),
                        function (item, index) {
                          return _c(
                            "q-chip",
                            {
                              key: index,
                              staticStyle: {
                                "margin-bottom": "4px !important",
                              },
                              attrs: {
                                outline: "",
                                square: "",
                                color: "light-green",
                                "text-color": "white",
                              },
                            },
                            [_vm._v(" " + _vm._s(item) + " ")]
                          )
                        }
                      )
                    : _vm._e(),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }